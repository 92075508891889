:root {
  /* --color-primary: hsl(31,36.1,66.9); */
  --color-primary: rgb(201, 172, 140);

  --color-primary-faded: rgb(214, 192, 168);

  /* --color-primary-faded: hsl(31,36.1,50); */

  --color-primary-dark: rgb(174, 129, 81);

  /* --color-dark: rgb(102,102,102); */

  --color-primary-darker: rgb(121, 90, 57);


  --color-light: rgba(240, 240, 240, .95);
}


.tmj-text-primary-dark {
  color: var(--color-primary-dark);
}



/* FLYER */
.text-light.flyer {
  /* color:#FFEFFD!important; */
  /* color: #FFE1FE!important; */
  color: #FFD3F9!important;
  /* color: #FFA2D3!important; */
  /* color: #E875B0!important; */
  font-weight: 700!important;
  word-spacing: 2px;
  text-shadow: #E875B0 2px 3px 10px;
}



/* GENERAL */

.tmj-btn-primary {
  /* border: .7px solid white; */
  /* border-radius: 3px; */
  border: none;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: var(--color-primary-dark);
  color: white!important;
  /* color: var(--color-primary); */
  padding: 1rem 1.5rem !important;
  text-align: center;
  /* transition: all .05s ease; */
}

.tmj-btn-primary:hover {
  background: var(--color-primary-darker);
}


.tmj-btn-register {
  /* border: .7px solid white; */
  /* border-radius: 3px; */
  border: none;
  width: 100%;
  max-width: 35rem;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  background-color: var(--color-primary-dark);
  color: white!important;
  /* color: var(--color-primary); */
  padding: 3rem 4.5rem !important;
  text-align: center;
  /* transition: all .05s ease; */
}

.tmj-btn-register:hover {
  background: var(--color-primary-darker);
}






@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,600;1,500&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-montserrat {
  font-family: "Montserrat", sans-serif;
}

.text-montserrat.thin,
.text-montserrat .thin {
  font-weight: 100 !important;
}
.text-montserrat.extra-light,
.text-montserrat .extra-light {
  font-weight: 200 !important;
}
.text-montserrat.light,
.text-montserrat .light {
  font-weight: 300 !important;
}
.text-montserrat.regular,
.text-montserrat .regular {
  font-weight: 400 !important;
}

/* JUMBOTRON */
.container-jumbotron {
  width: 100% !important;
  min-height: fit-content !important;
  padding: 5vh 0rem !important;
  /* height: 100vh; */
  overflow: hidden;
  background: url(./assets/background-jumbotron.jpg) no-repeat center center;
}
.container-jumbotron .container-heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.container-jumbotron .container-heading .container-left-heading {
  width: 50%;
  height: 100%;
}
.container-jumbotron .container-heading .container-left-heading h1 {
  color: #fff;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 4rem;
}
.container-jumbotron .container-heading .container-right-heading {
  width: 50%;
  height: 100%;
}
.container-jumbotron
  .container-heading
  .container-right-heading
  .container-text {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.container-jumbotron .container-heading .container-right-heading .text h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Times New Roman", Times, serif;
}
.container-jumbotron
  .container-heading
  .container-right-heading
  .text
  h1:nth-child(2) {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
}
.container-jumbotron
  .container-heading
  .container-right-heading
  .text
  h1:nth-child(1) {
  text-align: center;
}
.container-jumbotron .container-heading .container-right-heading .text-2 {
  text-align: right;
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
}
.container-jumbotron
  .container-heading
  .container-right-heading
  .text-2
  h1:nth-child(1) {
  font-size: 3.5rem;
  text-align: center;
  font-family: "Great Vibes", cursive;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
}
.container-jumbotron
  .container-heading
  .container-right-heading
  .text-2
  h1:nth-child(2) {
  color: #fff;
  font-size: 3.5rem;
  text-align: center;
  font-family: "Roboto Slab", sans-serif;
}
.container-jumbotron .container-profile {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 3rem;
}
.container-jumbotron .container-right-profile {
  width: 48%;
  margin: 0;
  padding: 0;
}
.container-jumbotron .container-right-profile .text {
  width: fit-content;
  height: fit-content;
  font-family: "Roboto Slab", sans-serif;
}
.container-jumbotron .container-right-profile .text h2 {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
  text-align: center;
  font-size: 2.5rem;
}
.container-jumbotron .container-right-profile .text h1 {
  color: #fff;
}
.container-jumbotron .container-left-profile {
  width: 48%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;
  gap: 2rem;
}
.container-jumbotron .container-left-profile .profile {
  color: orange;
  text-align: center;
}
.container-jumbotron .container-left-profile .profile h5 {
  margin: 0;
  font-size: 1rem;
}
.container-jumbotron .container-left-profile .profile p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 300;
}
.container-jumbotron .container-left-profile .profile img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  object-position: center;
}
.container-jumbotron .container-text {
  width: 100%;
  height: 100%;
  text-align: center;
  color: orange;
  font-size: 0.9rem;
}
.container-jumbotron .container-logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.container-jumbotron .container-logo img {
  margin-top: 3rem;
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 991px) {
  .container-jumbotron .container-heading .container-left-heading {
    width: 100%;
    height: 100%;
  }
  .container-jumbotron .container-heading .container-right-heading {
    width: 100%;
    height: 100%;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .container-text {
    width: 100%;
    height: 100%;
  }
  .container-jumbotron .container-heading {
    flex-direction: column;
  }
  .container-jumbotron .container-heading .container-left-heading h1 {
    font-size: 3rem;
  }
  .container-jumbotron .container-heading .container-right-heading .text h1 {
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .text
    h1:nth-child(2) {
    margin: 0;
  }
  .container-jumbotron .container-heading .container-right-heading .text-2 {
    padding-right: 20px;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(1) {
    font-size: 2.5rem;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(2) {
    font-size: 2.5rem;
    margin: 0;
  }
  .container-jumbotron .container-right-profile .text {
    margin-top: 30px;
  }
  .container-jumbotron .container-right-profile .text h2 {
    font-size: 2rem;
    margin: 0;
  }
  .container-jumbotron .container-right-profile .text h1 {
    font-size: 2rem;
    margin: 0;
  }
  .container-jumbotron .container-heading .container-left-heading {
    width: 100%;
    height: 100%;
  }
  .container-jumbotron .container-heading .container-right-heading {
    width: 100%;
    height: 100%;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .container-text {
    margin: 0 auto;
  }
  .container-jumbotron .container-right-profile .text {
    width: 100%;
  }
  .container-jumbotron .container-profile {
    flex-direction: column-reverse;
  }
  .container-jumbotron .container-right-profile {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .container-jumbotron .container-left-profile {
    width: 100%;
    justify-content: center;
  }
  .container-jumbotron .container-left-profile .profile img {
    width: 5rem;
    height: 5rem;
  }
  .container-jumbotron .container-left-profile {
    gap: 3rem;
  }
  .container-jumbotron .container-left-profile .profile img {
    width: 5rem;
    height: 5rem;
  }
  .container-jumbotron .container-logo img {
    width: 6rem;
    height: 6rem;
  }
}
@media (max-width: 767px) {
  .container-jumbotron .container-heading .container-left-heading h1 {
    font-size: 2.5rem;
  }
  .container-jumbotron .container-heading .container-right-heading .text h1 {
    font-size: 2.5rem;
    margin-top: 15px;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(1) {
    font-size: 2rem;
    margin-top: 15px;
  }
  .container-jumbotron
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(2) {
    font-size: 2rem;
  }
  .container-jumbotron .container-right-profile .text {
    margin-top: 15px;
  }
  .container-jumbotron .container-right-profile .text h2 {
    font-size: 1.5rem;
  }
  .container-jumbotron .container-right-profile .text h1 {
    font-size: 1.5rem;
  }
  .container-jumbotron .container-left-profile {
    gap: 1rem;
  }
  .container-jumbotron .container-left-profile .profile img {
    width: 4rem;
    height: 4rem;
  }
}

/* POSTER HOME */
.container-home {
  width: 100% !important;
  min-height: fit-content !important;
  padding: 5vh 0rem !important;
  overflow: hidden;
  background: url(./assets/background-home.jpg) no-repeat center center;
}
.container-home .container-heading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-home .container-heading .container-right-heading .container-text {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.container-home .container-heading .container-right-heading .text h1 {
  color: #fff;
  font-size: 4rem;
  font-family: "Times New Roman", Times, serif;
}
.container-home
  .container-heading
  .container-right-heading
  .text
  h1:nth-child(2) {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
}
.container-home
  .container-heading
  .container-right-heading
  .text
  h1:nth-child(1) {
  text-align: center;
}
.container-home .container-heading .container-right-heading .text-2 {
  text-align: right;
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
}
@media (max-width: 991px) {
  .container-home .container-heading .container-left-heading {
    width: 100%;
    height: 100%;
  }
  .container-home .container-heading .container-right-heading {
    width: 100%;
    height: 100%;
  }
  .container-home .container-heading .container-right-heading .container-text {
    margin: 0 auto;
  }
}
.container-home
  .container-heading
  .container-right-heading
  .text-2
  h1:nth-child(1) {
  font-size: 3.5rem;
  text-align: center;
  font-family: "Great Vibes", cursive;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
}
.container-home
  .container-heading
  .container-right-heading
  .text-2
  h1:nth-child(2) {
  color: #fff;
  font-size: 3.5rem;
  text-align: center;
  font-family: "Roboto Slab", sans-serif;
}
.container-home .heading-text {
  font-size: 5rem;
  background-clip: text;
  font-family: "Playfair Display", serif;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
}
.container-home .container-profile {
  display: flex;
  justify-content: center;
}
.container-home .container-right-profile {
  width: 48%;
  margin: 0;
  padding: 0;
}
.container-home .container-right-profile .text {
  width: fit-content;
  height: fit-content;
  font-family: "Roboto Slab", sans-serif;
}
.container-home .container-right-profile .text h2 {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to top,
    #8f9012,
    #8f9012,
    #e9eb00,
    #8f9012,
    #8f9012
  );
  text-align: center;
  font-size: 2.5rem;
}
.container-home .container-right-profile .text h1 {
  color: #fff;
}
.container-home .container-left-profile {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  gap: 5rem;
}
.container-home .container-left-profile .profile {
  color: black;
  text-align: center;
}
.container-home .container-left-profile .profile h5 {
  margin: 0;
  font-size: 1rem;
}
.container-home .container-left-profile .profile p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 300;
}
.container-home .container-left-profile .profile img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  object-position: center;
}
.container-home .container-text {
  width: 100%;
  height: 100%;
  text-align: center;
  color: black;
  font-size: 0.9rem;
}
.container-home .container-logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.container-home .container-logo img {
  margin-top: 3rem;
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 991px) {
  .container-home .container-profile {
    flex-direction: column-reverse;
  }
  .container-home .container-right-profile {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .container-home .container-left-profile {
    width: 100%;
    justify-content: center;
  }
  .container-home .container-left-profile {
    gap: 3rem;
  }
  .container-home .container-right-profile .text {
    width: 100%;
  }
  .container-home .heading-text {
    background-image: linear-gradient(to top, #8f9012, #e9eb00, #8f9012);
  }
  .container-home .container-left-profile .profile img {
    width: 5rem;
    height: 5rem;
  }
  .container-home .container-logo img {
    width: 6rem;
    height: 6rem;
  }
  .container-home .container-heading .container-right-heading .text h1 {
    font-size: 3rem;
  }
  .container-home
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(1) {
    font-size: 2.5rem;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .container-home
    .container-heading
    .container-right-heading
    .text-2
    h1:nth-child(2) {
    font-size: 2.5rem;
    margin: 0;
  }
  .container-home .heading-text {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .container-home .container-left-profile {
    gap: 1rem;
  }
  .container-home .container-left-profile .profile img {
    width: 4rem;
    height: 4rem;
  }
  .container-home .container-heading .container-right-heading .text h1 {
    font-size: 2.5rem;
  }
  .container-home .heading-text {
    font-size: 3rem;
  }
}

/* FQA */
.container-accordion {
  width: 50%;
}

@media (max-width: 1199px) {
  .container-accordion {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .container-accordion {
    width: 100%;
  }
}

/* HOTEL */
.container-slide-show {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.wrapper-slide-show {
  width: 100%;
}

.wrapper-slide-show img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover;
  object-position: center;
}

.container-slide-show .wrapper-slide-show img:nth-child(1) {
  z-index: 1;
  animation: fade 16s infinite;
}

.container-slide-show .wrapper-slide-show img:nth-child(2) {
  z-index: 2;
  animation: fade 16s infinite 4s;
}

.container-slide-show .wrapper-slide-show img:nth-child(3) {
  z-index: 3;
  animation: fade 16s infinite 8s;
}

.container-slide-show .wrapper-slide-show img:nth-child(4) {
  z-index: 4;
  animation: fade 16s infinite 12s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}




/* NOTICE */
.tmj-notice {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  /* color: #ffffff; */
  margin: 0;
  padding: .75rem 0;
  text-align: center;
  letter-spacing: 3px;
}



/* HOST */

.tmj-host {
  padding-bottom: 10rem;
}

@media (max-width: 991px) {
  .tmj-host {
    padding-bottom: 4rem;
  }
}



/* NAVTOP */

.tmj-navtop-logo-img {
  width: 7rem;
}

.tmj-navtop-logo-text {
  width: 22rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

@media (max-width: 1100px) {
  .tmj-navtop-logo-img {
    width: 6rem;
  }
  .tmj-navtop-logo-text {
    width: 17rem;
  }
}

@media (max-width: 510px) {
  .tmj-navtop-logo-img {
    width: 5rem;
  }
  
  .tmj-navtop-logo-text {
    width: 13rem;
  }
}

@media (max-width: 400px) {
  .tmj-navtop-logo-img {
    display: none;
  }
  
  .tmj-navtop-logo-text {
    margin-left: 0;
  }
}



/* FOOTER */

.tmj-footer {
  color: var(--color-light);
  overflow: hidden;
}

.tmj-footer-link {
  cursor: pointer;
}

.tmj-footer-org {
  display: flex;
  align-items: center;
}

.tmj-footer-org:hover a {  
  /* background: rgba(201, 172, 140, .2); */
  background-color: white;
  color: rgba(0,0,0,.8);
}

.tmj-footer-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 190px;
}

.tmj-footer-logo-img {
  width: 375px;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tmj-footer-end {

}

@media (max-width: 991px) {
  .tmj-footer {
    text-align: center;
  }

  .tmj-footer-logo {
    /* margin-bottom: 3rem; */
    min-height: 500px;
  }

  .tmj-footer-logo-img {
    width: auto;
    /* object-fit: cover; */
    /* object-position: center; */
    /* position: relative;
    top: 0;
    left: 0;
    transform: none; */
  }

  .tmj-footer-end {
   padding-top: 3rem;
  }
}

@media (max-width: 400px) {
  .tmj-footer-logo-img {
    width: 600px;
  }
}




/*  */
.tmj-section-header {
  font-size: 60px;
  line-height: 58px;
  margin: auto;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  position: relative;
}
.tmj-section-header::after {
  content: "";
  display: block;
  margin-top: 4rem;
  border-bottom: 3px solid var(--color-primary);
  /* width: 140px; */
  width: 12rem;
  position: absolute;
  /* top: 100px; */
  bottom: 2.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tmj-footer .tmj-section-header {
  font-size: 21px;
  text-align: left;
  padding-top: 1rem;
}
.tmj-footer .tmj-section-header::after {
  border-bottom: 2px solid var(--color-primary);
  left: 0%;
  margin-top: 1rem;
  width: 4.5rem;
  transform: none;
}

.tmj-section-title {
  font-size: 33px;
  line-height: 31px;
  margin: auto;
  color: var(--color-primary);
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  position: relative;
}
.tmj-section-register-support-heading {
  font-size: 3rem;
  color: var(--color-primary);
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.tmj-section-register-support-paragraph {
  font-size: 1.5rem;
  color: var(--color-primary);
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.tmj-register-title-success {
  font-size: 1.5rem;
  color: var(--color-primary);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.tmj-right-section-register-support {
  width: 35%;
}
.tmj-left-section-register-support {
  width: 65%;
  min-width: 400px;
}
@media screen and (max-width: 991px) {
  .tmj-right-section-register-support {
    width: 100%;
    margin: 2rem 0;
  }
  .tmj-left-section-register-support {
    width: 100%;
    min-width: 0;
  }
}

/* WORKSHOP */
.tmj-title-workshop {
  font-size: 1.2rem;
  color: var(--color-primary);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.card-workshop {
  width: 25%;
}

.tmj-schedule-container {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: center;
}

.tmj-card-list {
  /* border-bottom: 1px solid rgba(102,102,102,.1); */
  border-bottom: 1px solid rgba(220,220,220,.4);
  padding: 1rem 0;
  min-height: 108px;
  /* margin-left: 3rem; */
}

.tmj-card-list.workshop {
  /* border-bottom: 1px solid rgba(102,102,102,.1); */
  border-bottom: 1px solid rgba(220,220,220,.4);
  /* padding: 1rem 0; */
  /* height: 180px; */
  /* margin-left: 3rem; */
}

.tmj-card-date {
  width: 102px;
  height: 108px;
  /* background: rgba(102,102,102,1); */
  background: rgba(240,240,240,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.tmj-card-date .date {
  font-size: 45px;
  /* color: white; */
  color: rgba(52,52,52,.8);
  line-height: 45px;
  font-weight: 900;
}

.tmj-card-date .day {
  font-size: 18px;
  /* color: white; */
  color: black;
  opacity: .4;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.tmj-label-hour {
  width: 68px;
  height: 68px;
  min-width: 68px;
  min-height: 68px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  font-size: 15px;
  font-weight: 800;
  color: var(--color-primary);
  white-space: nowrap;
}
.workshop .tmj-list-schedule {
  /* margin-left: 1.5rem; */
  max-width: 280px;
  /* padding: .5rem 0; */
  /* margin: .5rem 0 .5rem 1.5rem; */
}

.tmj-list-schedule {
  margin-left: 1.5rem;
  max-width: 210px;
  /* padding: .5rem 0; */
  /* margin: .5rem 0 .5rem 1.5rem; */
}

.tmj-list-schedule .date {
  /* color: rgba(102,102,102,.5); */
  color: rgba(220, 220, 220, .7);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.tmj-list-schedule .title {
  /* color: rgba(1,22,39,1); */
  color: var(--color-light);
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.tmj-list-schedule.workshop .title {
  /* color: rgba(1,22,39,1); */
  font-size: 18px;
}

.tmj-list-schedule .hour {
  /* color: rgba(102,102,102,.8); */
  color: rgba(220, 220, 220, .8);
  font-size: 13px;
  /* white-space: nowrap; */
  /* letter-spacing: 1px; */
}

.tmj-list-schedule i {
  font-size: 11px;
  color: var(--color-primary);
  margin-right: .5rem;
}

.tmj-list-schedule .performer {
  /* color: rgb(102,102,102); */
  color: rgba(200, 200, 200, 1);
  font-size: 13px;
  white-space: nowrap;
}

.tmj-dot {
  position: relative;
  margin: 0 1rem;
}

.tmj-dot::after {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  overflow: hidden;
  /* background-color: rgba(102,102,102,.7); */
  background-color: rgba(220,220,220,.7);
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* CUSTOM ANTD */
.ant-steps-item-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-primary) !important;
  font-family: "Montserrat", sans-serif;
  line-height: normal !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--color-primary) !important;
  border: none;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff !important;
  border: 1px solid var(--color-primary) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--color-primary) !important;
}
.ant-steps-item-finish .ant-steps-icon {
  color: #fff !important;
}
.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: var(--color-primary) !important;
}
.ant-steps-item-tail::after {
  background-color: var(--color-primary-dark) !important;
}

/* CARD SCHADULE */
.card-container {
  /* background-color: #1a1919; */
  background: rgba(150,150,150,.05);
}
.card-container:hover {
  /* background-color: #272626; */
  background: rgba(150,150,150,.12);
}
.tmj-profile-text-date {
  position: absolute;
  top: 6rem;
  right: 0;
}
.text-date {
  /* font-family: "Bodoni Moda", serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 3.2rem;
  font-weight: 300;
  font-style: italic;
  /* color: #e8b923; */
  color: var(--color-primary);
  text-align: right;
  margin: 0;
  white-space: nowrap;
}
.text-heading {
  /* font-family: "Bodoni Moda", serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: 4rem;
}
.text-normal {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}
.text-small {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
}
.img-schadule {
  width: 30%;
  object-fit: cover;
  object-position: center;
}
.decoration {
  width: 200px;
  height: 1.5px;
  /* background-color: #e8b923; */
  background-color: var(--color-primary);
}

@media (max-width: 1199px) {
  .text-date {
    font-size: 2rem;
  }
  .text-heading {
    font-size: 2.5rem;
  }
  .text-normal {
    font-size: 1.2rem;
  }
  .text-small {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .text-date {
    font-size: 2rem;
  }
  .text-heading {
    font-size: 2rem;
  }
  .text-normal {
    font-size: 1.2rem;
  }
  .text-small {
    font-size: 1rem;
  }
  .tmj-profile-text-date {
    top: 5rem;
  }
  .decoration {
    width: 100px;
  }
}


.tmj-profile-img-container {
  width: 150px;
  height: 150px;
  padding: 12.5px;
  box-shadow: 0 1rem 3rem -1rem rgba(0,0,0,.25);
  /* box-shadow: 0 1rem 3rem -1rem rgba(220,220,220,.25); */
  /* border: .5px solid rgba(220,220,220,.4); */
}




/* FAQ */
.tmj-title-accordion {
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.tmj-paragraph-accordion {
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
/* Custom Accordion */
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(.7) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}
.accordion-button:focus {
  box-shadow: none !important;
}



/* SCHEDULES */

.tmj-schedules-padding {
  padding: 0% 15% 8%;
}

@media (max-width: 991px) {
  .tmj-schedules-padding {
    padding: 0% 5% 8%;
  }
}




/* FOOTER */

.tmj-footer {
  font-family: "Montserrat", sans-serif;
}

.tmj-footer-header {
  font-size: 18px;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 3rem;
  font-weight: 600;
  position: relative;
}
.tmj-footer-header::after {
  content: "";
  display: block;
  margin-top: .7rem;
  border-bottom: 2px solid var(--color-primary);
  width: 4.5rem;
  position: absolute;
}
@media (max-width: 991px) {
  .tmj-footer-header {
    text-align: center;
  }
  .tmj-footer-header::after {
    left: 50%;
    transform: translateX(-50%);
  }
}


.tmj-bg-faded {
  background-color: rgba(100,100,100,1);
  color: white;
  transition: all .4s;
}

.tmj-bg-faded.hover:hover {
  background-color: white;
  color: rgba(0,0,0,.8);
}

.tmj-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .tmj-footer .row {
  padding-right: 2rem;
  padding-left: 2rem;
} */

.tmj-container-textarea {
  /* padding-right: 1rem; */
}

.tmj-textarea {
  outline: none;
  border: .7px solid rgba(240,240,240,.8);
  border-radius: 3px;
  min-height: 5rem;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  transition: all .2s ease;
  font-family: "Montserrat", sans-serif;
  vertical-align: text-top;
  color: white;

  word-wrap: "break-word";
  word-break: "break-word";
}

.tmj-textarea:focus {
  background: white!important;
  color: rgba(0,0,0,.7);
}

.tmj-textarea-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  /* Apply min height of 4 lines */
  line-height: 1.2;
  min-height: calc(1.2em * 4);
  
  /* Border styles */
  border: 1px solid gray;
  border-radius: 5px;
}

.tmj-btn-outline-footer {
  border: .7px solid white;
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  color: white;
  /* color: var(--color-primary); */
  padding: 1rem 1rem;
  transition: all .15s ease;
}

.tmj-btn-outline-footer:hover {
  background: rgba(201, 172, 140, .2);
}




.tmj-textarea-2 {
  /* border: .7px solid rgba(240,240,240,.8); */
  /* border-radius: 3px; */
  outline: none;
  border: none;
  background: rgba(255,255,255,.1);
  min-height: 5rem;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  transition: all .2s ease;
  font-family: "Montserrat", sans-serif;
  vertical-align: text-top;
  color: white;
  /* color: rgba(0,0,0,.7); */

  word-wrap: "break-word";
  word-break: "break-word";

  margin-right: 3rem;
}

.tmj-textarea-2:focus {
  /* background: rgba(255,255,255,.5); */
  /* color: rgba(0,0,0,.7); */
}

.tmj-btn-outline-footer-2 {
  /* border: .7px solid white; */
  /* border-radius: 3px; */
  border: none;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: var(--color-primary-dark);
  color: white;
  /* color: var(--color-primary); */
  padding: 1rem 1rem;
  /* transition: all .15s ease; */
}

.tmj-btn-outline-footer-2:hover {
  background: var(--color-primary-darker);
}





/* HOTEL */

.tmj-hotel-img-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.tmj-hotel-img-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.tmj-hotel-img-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media (max-width: 991px) {
  .tmj-hotel-img-column {
    flex: 50%;
    max-width: 50%;
  }
  .card-workshop {
    width: 50%;
  }
}

/* @media (max-width: 600px) {
  .tmj-hotel-img-column {
    flex: 100%;
    max-width: 100%;
  }
} */



/* OTHER HOTELS */
.tmj-btn-other-hotel {
  font-family: "Montserrat", sans-serif;
  line-height: 3rem;
  font-size: 3rem;
  font-weight: 700;
  /* color: #ffffff; */
  margin: 0;
  padding: 6rem 0 2rem;
  text-align: center;
  /* letter-spacing: 2px; */
}




/* CAST */

.tmj-cast-shadow {
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  background-color: rgba(0,0,0,.3);
}


.tmj-cast-title {
  font-size: 36px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: white;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 910px) {
  .tmj-cast-title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .tmj-cast-title {
    font-size: 24px;
  }
  .card-workshop {
    width: 100%;
  }
}









/* RESPONSIVE */


@media (max-width: 475px) {

  /* SECTION SCHEDULES */

  .tmj-schedule-container {
    flex-direction: column;
  }

  .tmj-card-date {
    /* width: 82px; */
    /* height: 85px; */
    width: 120px;
    height: 127px;
    margin: 1rem 0 0 0;
    /* margin: 0 auto 1rem; */


    /* background: rgba(240,240,240,.8); */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
  }
  
  .tmj-card-date .date {
    font-size: 48px;
    line-height: 48px;
    /* font-size: 36px; */
    /* line-height: 36px; */

    /* color: white; */
    /* color: rgba(52,52,52,.8);
    font-weight: 900; */
  }
  
  .tmj-card-date .day {
    font-size: 19px;
    /* font-size: 15px; */
    
    /* color: white; */
    /* color: black;
    opacity: .4;
    text-transform: uppercase; */
    letter-spacing: 1.5px;
  }

  .tmj-label-hour {
    width: 59px;
    height: 59px;
    font-size: 12px;
    font-weight: 1000;
    /* border-radius: 100%; */
    /* border: 1px solid var(--color-primary); */
    /* color: var(--color-primary); */
    /* white-space: nowrap; */
  }

  .tmj-card-list {
    /* margin-left: 1rem; */
    /* height: 99px; */
  }

  .tmj-list-schedule {
    margin-left: .7rem;
  }
  
  .tmj-list-schedule .date {
    /* color: rgba(102,102,102,.5); */
    /* color: rgba(220, 220, 220, .7); */
    /* text-transform: uppercase; */
    font-size: 11px;
    letter-spacing: .8px;
    margin-bottom: 0.3rem;
  }
  
  .tmj-list-schedule .title {
    /* color: rgba(1,22,39,1); */
    color: var(--color-light);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }

  .tmj-list-schedule.workshop .title {
    /* color: rgba(1,22,39,1); */
    font-size: 15px;
  }
  
  .tmj-list-schedule .hour {
    /* color: rgba(102,102,102,.8); */
    /* color: rgba(220, 220, 220, .8); */
    font-size: 11px;
    /* letter-spacing: 1px; */
  }
  
  .tmj-list-schedule i {
    font-size: 10px;
    /* color: var(--color-primary); */
    margin-right: .3rem;

  }
  
  .tmj-list-schedule .performer {
    /* color: rgb(102,102,102); */
    /* color: rgba(200, 200, 200, 1); */
    font-size: 11px;
  }

  .tmj-btn-register {
    /* border: none; */
    /* width: 100%; */
    /* max-width: 35rem; */
    font-size: 1.5rem;
    /* font-family: "Montserrat", sans-serif; */
    /* background-color: var(--color-primary-dark); */
    /* color: white!important; */
    /* padding: 3rem 4.5rem !important; */
    /* text-align: center; */
  }

  /*  */
}

@media (max-width: 440px) {
  .tmj-btn-other-hotel {
    font-size: 33px;
    line-height: 33px;
  }

  .tmj-section-title {
    font-size: 30px;
    line-height: 30px;
  }

}


@media (max-width: 400px) {
  .tmj-section-header {
    font-size: 33px;
    line-height: 33px;
  }

  .tmj-btn-other-hotel {
    font-size: 27px;
    line-height: 27px;
  }

  .tmj-section-title {
    font-size: 27px;
    line-height: 27px;
    /* margin: auto;
    color: var(--color-primary);
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    position: relative; */
  }

  .tmj-early-bird-sub-title {
    font-size: 1.2rem;
  }

  .tmj-btn-register {
    /* border: none; */
    /* width: 100%; */
    /* max-width: 35rem; */
    font-size: 1.3rem;
    /* font-family: "Montserrat", sans-serif; */
    /* background-color: var(--color-primary-dark); */
    /* color: white!important; */
    /* padding: 3rem 4.5rem !important; */
    /* text-align: center; */
  }
}


/* REGISTRATION */

.tmj-card-package {
  transition: transform .2s ease;
}

.tmj-card-package:hover {
  border-color: rgba(0,0,0,.2);
  box-shadow: 0px 1px 4px 2px rgba(0,0,0,.1);
  -webkit-box-shadow: 0px 1px 4px 2px rgba(0,0,0,.1);
  -moz-box-shadow: 0px 1px 4px 2px rgba(0,0,0,.1);
  cursor: pointer;
  /* transform: scale(1.08); */
  z-index: 11;
}

.tmj-select-country-code {
  width: 25%;
}

.tmj-card-package.selected {
  border-color: var(--color-primary);
  border-width: 2px;
  /* transform: scale(1.1); */
  z-index: 10;
}

.tmj-card-package.selected:hover {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  cursor: default;
}

@media (max-width: 767px) {
  .tmj-select-country-code {
    width: 100%;
  }
}


.fa-instagram:hover {
  color: black;
}